<template>
    <div
        data-scroll
        data-scroll-id="nameParallax"
        :class="{ hidden }"
        class="brand-name-slider"
    >
        <div
            v-for="column in 3"
            :key="column"
            :class="{ hidden }"
            class="name-list"
        >
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        hidden: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.brand-name-slider /deep/ {
    display: flex;
    flex-direction: column;
    grid-gap: 43px;
    padding-top: 0;
    height: 600px;
    position: relative;
    @media only screen and (max-width: 1440px) and (min-width: 1025px) {
        height: 500px;
    }
    @media only screen and (max-width: 1024px) and (min-width: 769px) {
        height: 350px;
    }
    @media only screen and (max-width: 768px) {
        height: 250px;
    }
    @media only screen and (max-width: 1023px) {
        margin-top: 55px;
    }

    .name-list {
        @include center-children;
        grid-gap: 30px;
        position: absolute;
        opacity: 0.2;
        @for $i from 1 through 3 {
            &:nth-child(#{$i}) {
                top: $i * 100px;
            }
            @media only screen and (max-width: 1440px) and (min-width: 1025px) {
                &:nth-child(#{$i}) {
                    top: $i * 75px;
                }
            }
            @media only screen and (max-width: 1024px) and (min-width: 768px) {
                &:nth-child(#{$i}) {
                    top: $i * 50px;
                }
            }
            @media only screen and (max-width: 768px) {
                &:nth-child(1) {
                    top: 0px;
                }
                &:nth-child(2) {
                    top: 55px;
                }
                &:nth-child(3) {
                    top: 110px;
                }
            }
        }

        &:nth-child(1) {
            left: -200px;
        }

        &:nth-child(2) {
            left: 50%;
            transform: translateX(-50%) !important;

            .brand-logo-container {
                &:nth-child(4) {
                    opacity: 0;
                }
            }
        }

        &.hidden {
            svg {
                opacity: 0 !important;
            }
        }

        svg {
            width: 308px;
            @media only screen and (max-width: 1439px) and (min-width: 768px) {
                width: 220px;
            }
            @media only screen and (max-width: 767px) {
                width: 213px;
            }
        }

        .title {
            font-size: 55px;
            opacity: 0.1;
        }
    }
}

.brand-name-slider.hidden {
    height: 400px;
    @media only screen and (max-width: 1023px) {
        height: 250px;
    }
}
</style>
